// Variables CSS

$main_color: #535454;
$second_color: #3E3F3F;

$background_body: #C6517A;
$background_sidebar: #787878;
$background_content: #fff;
$background_title: #e5e5e5;

$texte_blanc: #fff;
$noir: #000;

$boutons_color: #535454;

// Général

body {
  background-color: $background_body;
}

a {
  color: $main_color;
}

h2.title_section, h2,
.h2.title_section, .h2,
h1, .h1 {
  background: $background_title;
  color: $main_color;
  a, a:hover{
    color:inherit;
  }
}

.button {
  background: $boutons_color;
  color: $background_content;

  &:hover {
    background: darken($boutons_color, 10%);
  }
}

.zoom {
  &::before {
    background: $boutons_color;
  }

  &:hover {
    &::before {
      background: darken($boutons_color, 10%);
    }
  }
}

.button, .addmsglo, .addbasket, .zoom {
  background: $boutons_color;
  border: transparent;
}

.page {
  background: $background_content;
}

// Header

header {
  background-color: $background_sidebar;

  .slogan {
    color: $second_color;
  }
}

nav#nav-principal {

  ul li.actif {
    background: $background_body;

    > a {
      color: $main_color;
    }
  }

  ul li:hover {
    background-color: $background_body;
  }

  ul li a {
    color: $texte_blanc;
  }

  a:hover, a.actif {
    color: $main_color;
  }

  ul li ul li a:hover, ul li ul li a.actif {
    color: $main_color;
  }
}

// Fils d'ariane

.arianne {
  a, a::after, a::before {
    background: $background_content;
    color: $second_color;
  }

  a:hover:after, a.actif:after, a:hover:before, a.actif:before, a:hover, a.actif {
    background: $second_color;
    color: $background_content;
  }
}

// Boutiques

.produits {
  background-color: $background_content;

  .prixprod {
    color: $main_color;
  }

  .produit_etiquette {
    background: $second_color;

    &::after {
      border-color: $second_color rgba(65, 129, 217, 0) rgba(65, 129, 217, 0);
    }
  }

  select {
    background: none repeat scroll 0 0 $main_color;
    border: 1px solid $main_color;
    color: $background_content;
  }
}

.template_boutique_all {

  .produits {
    .note-produit {
      color: $main_color;
    }
    .remise {
      color: $background_content;
      background: $second_color;
    }

    .productImageWrap img {
      border-bottom: 2px solid $main_color;
    }

    .zoom {
      &::after {
        background: $noir;
      }
    }
  }

  &.template_boutique_accueil {

    &.accueil_boutique_template_1 {
      .bx-controls-direction {

        a.bx-prev {
          background: $boutons_color;

          &:hover {
            background: darken($boutons_color, 10%);
          }

          &::before {
            color: $background_content;
          }
        }

        a.bx-next {
          background: $boutons_color;

          &:hover {
            background: darken($boutons_color, 10%);
          }

          &::before {
            color: $background_content;
          }
        }
      }
    }
  }

  &.template_boutique {

    &.template_boutique_3 .produits .productImageWrap img {
      border-right: 2px solid $main_color;
    }
  }
}

// Fiche produit

#fiche-produit {

  .produit_etiquette {
    background: $second_color;

    &::after {
      border-color: $second_color rgba(65, 129, 217, 0) rgba(65, 129, 217, 0);
    }
  }

  select {
    background: none repeat scroll 0 0 $main_color;
    border: 1px solid $main_color;
    color: $background_content;
  }

  .wrap-description {
    background: none repeat scroll 0 0 $background_content;

    > h3, > .h3, > .row {
      background: none repeat scroll 0 0 $background_title;
      color: $main_color;
    }
  }
}

.template_fiche_produit {

  #fiche-produit {

    .remise-produit {
      background: $second_color;
    }

    .wrap-images {
      background: $background_content;

      .previous, .bx-prev {
        background: $boutons_color;
        color: $background_content;

        &:hover {
          background: darken($boutons_color, 10%);
        }
      }

      .next, .bx-next {
        background: $boutons_color;
        color: $background_content;

        &:hover {
          background: darken($boutons_color, 10%);
        }
      }
    }

    .wrap-description {
      .note-produit {
        color: $main_color;
      }

      .radio_unique {
        background: rgba(255,255,255,0.1);
        color: $boutons_color;
        border-color: $boutons_color;

        &:hover, &.radio-checked {
          color: $background_content;
          border-color: $boutons_color;
          background: $boutons_color;
        }
      }

      .radio_color {
        box-shadow: none;
        border-radius: 0;
      }

      .prix {

        .prixprod {
          color: $background_content;
          background: $main_color;
        }

        .oldprix {
          color: #444;
        }
      }
    }

    .wrap-details {
      background: $background_content;
    }

    .wrap-details.bloc-avis-clients .moyenne_etoiles, .noteComment {
      color: $main_color;
    }

    h4, .h4 {
      background: $background_title;
      color: $main_color;
    }
  }

  &.template_fiche_produit_1 #fiche-produit .wrap-images .grande-image {
    border-bottom: 2px solid $main_color;
  }

  // Affichage particulier template fiche produit 3

  &.template_fiche_produit_3 #fiche-produit {

    h1, .h1 {
      background: $background_title;
      color: $main_color;
    }

    .wrap-description .details-descr-com {
      .side-tabs > a {
        color: $background_content;
      }

      .in-onglet {
        border: 1px solid #ccc;
        background: $background_content;
      }
    }
  }
}

// Albums

.template_album {

  .galerie:hover {
    box-shadow: none;

    .commentP {
      color: $texte_blanc;
    }

    .addcommentaires {
      box-shadow: 0 0 10px #444;
    }
  }

  .addcommentaires {
    background: $main_color;
    border: $main_color;
    color: $background_content;

    .fa-comments-o {
      margin-right: 3px;
    }
  }

  .galerie{
    &, .mask{
      background: $main_color;
    }
    .commentP p{
      color:$texte_blanc;
    }
  }
}

// Livre d'or

.template_livreor {

  .message_lo {
    background-color: $background_content;
    border: $background_content;

    h4, .h4 {
      color: #353E3C;
    }

    .web {
      background: none repeat scroll 0 0 $background_title;

      a {
        color: $main_color;
      }
    }

    .note {
      background: $main_color;
      color: $background_content;
    }
  }

  &.template_livreor_2 {
    .IDLO {
      background: $background_content;
    }

    .message_lo {

      &:nth-child(odd) .contenuMsgLO .blcFleche::before {
        background: $background_content;
      }

      .blcFleche::before {
        background: $background_content;
      }

      .contenuMsgLO {
        background: $background_content;
      }
    }
  }

  &.template_livreor_3 .message_lo {
    background: $background_content;
  }
}

.template_contact {
  background: $background_content;

  h2, .h2 {
    background: $background_title;
    color: $main_color;
  }


  &.template_contact_5 {

    .contenu-editable-contact {
      background: $background_content;
    }

    .blocForm {
      background: $background_content;
    }
  }
}

// panier

.cmonsite-panier-2 {

  #nav-principal {

    .template-panier-2 {
      background: $main_color;

      #header-panier {
        color: $background_content;
      }

      .count-live {
        color: $main_color;
        background: $background_content;
      }
    }
  }
}

.template-panier {
  &.template-panier-2 {

    #paniercontent::before {
      background: linear-gradient(90deg, transparentize($noir, 0.6), transparentize($noir, 1));
    }
  }

  &.template-panier-3 {
    border: 1px solid $main_color;

    .connexion p {
      color: $main_color;

      a {
        color: $main_color;
      }
    }

    #header-panier {
      color: $main_color;

      .count-live {
        background: $main_color;
        color: $background_content;
      }
    }

    #paniercontent::before {
      background: linear-gradient(90deg, transparentize($noir, 0.6), transparentize($noir, 1));
    }
  }
}


// slider

.accueil_diaporama_template {

  .bx-wrapper .bx-pager.bx-default-pager a {
    background: $main_color;

    &.active {
      background: darken($main_color, 10%);
    }
  }

  a.bx-prev {
    background: $boutons_color;
    color: $background_content;

    &:hover {
      background: darken($boutons_color, 10%);
    }
  }

  a.bx-next {
    background: $boutons_color;
    color: $background_content;

    &:hover {
      background: darken($boutons_color, 10%);
    }
  }

  .slide .slide-text .content-slide-text > p {
    background: $boutons_color;
    color: $background_content;
  }

  &.accueil_diaporama_template_1 {
    background: $background_content;
  }

  // Affichage particulier template slider 3

  &.accueil_diaporama_template_3 {
    background: $background_content;

    #bx-pager {
      background: transparentize($noir, 0.9);
    }
  }

  &.accueil_diaporama_template_4 {
    background: $background_content;
  }

  &.accueil_diaporama_template_5 {
    background: $background_content;
  }
}

// Blog

.apercu-article .bloc-article {
  background: $background_content;
}

.blog-sidebar .blog-sidebar-bloc {
  background: $background_content;
}

#wrapper {
  .single-article {
    background: $background_content;
  }
}

@media screen and (max-width: 1025px) {
  header {
    nav#nav-principal .template-panier.template-panier-2 #paniercontent::before {
      background: linear-gradient(180deg, transparentize($noir, 0.6), transparent);
    }

    .template-panier.template-panier-3 #paniercontent::before {
      background: linear-gradient(180deg, transparentize($noir, 0.6), transparent);
    }
  }

  .menu nav#nav-principal {

    .menu_burger {
      background: $main_color;

      &::before {
        color: $background_content;
      }
    }

    ul li.actif {
      background: $background_body;

      > a {
        color: $main_color;
      }
    }

    ul li:hover {
      background-color: $background_body;
    }

    ul li a {
      color: $texte_blanc;
    }

    a:hover, a.actif {
      color: $main_color;
    }

    ul li ul li a:hover, ul li ul li a.actif {
      color: $main_color;
    }

    > ul {
      background: $background_sidebar;
    }
  }
}

.button.button-secondary {
  background: orange;

  &:hover {
    background: #444;
  }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
  background: $second_color;
  border-color: $second_color;

  a {
    color: #fff;
  }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
  border-color: $second_color;
  color: $second_color;

  a, span {
    color: $second_color;
  }
}

#flux-panier.template_flux_panier.template_flux_panier_1 {
  color: #000;
}